import React from 'react';
import background from '../assets/img/Rectangle 134.png';
import blogOne from '../assets/img/blog/pexels-bhaveshjain-3988075.jpg';
import blogTwo from '../assets/img/blog/pexels-rachel-claire-8112558.jpg';
// import blogThree from '../assets/img/blog/pexels-ravijha95-12112985.jpg';
// import blogFive from '../assets/img/blog/pexels-rachel-claire-8112531.jpg';
import blogFour from '../assets/img/blog/pexels-rachel-claire-8112555.jpg';
// import blogSix from '../assets/img/blog/pexels-subhajit-paul-213168853-12375961.jpg';

const Blog = () => {
  return (
    <div className="bg-gray-100">
      <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-6 px-4 sm:px-28 lg:px-16">
        Journey Beyond Borders: <br className="block sm:hidden" /> <br />Explore, Discover, Dream
      </h1>

      <div className="relative bg-gray-100 flex flex-col lg:flex-row px-6 lg:px-10  rounded-lg shadow-lg py-0 lg:py-0">
        {/* Left section  */}
        <div className="w-full lg:w-2/5 bg-sky-700 text-white flex flex-col justify-center items-start px-6 py-16 lg:px-8 lg:py-20 rounded-t-lg lg:rounded-l-lg lg:rounded-tr-none lg:rounded-br-none shadow-lg">
          {/* <p className="mt-4 text-lg lg:text-l max-w-lg font-semibold">VoyEase</p> */}
          <h6 className="mt-4 text-lg lg:text-xl max-w-lg" >VoyEase, The Ultimate Globe-Trotter's Service</h6>
          <p className="mt-4   max-w-lg font-thin">
          Embark on a global adventure with us ! From iconic landmarks to hidden gems, this guide will lead you to unforgettable destinations. Discover vibrant cultures, indulge in local flavors, and make every journey a story worth telling. Your world adventure starts here !
          </p>
          <button className="mt-8 lg:mt-10 bg-white text-blue-800 py-3 px-6 rounded-lg hover:bg-gray-200">
            Book Now &rarr;
          </button>
        </div>

        {/* Right section */}
        <div className="w-full lg:w-3/5 rounded-b-lg lg:rounded-r-lg lg:rounded-tl-none lg:rounded-bl-none shadow-lg">
          <img
            src={background}
            alt="Hero"
            className="w-full h-64 lg:h-full object-cover rounded-b-lg lg:rounded-r-lg lg:rounded-tl-none lg:rounded-bl-none"
          />
        </div>
      </div>



      {/* Blog Posts Section */}
      <div className="py-16 px-4 md:px-8 max-w-screen-xl mx-auto">
        {/* <h2 className="text-4xl font-bold text-center mb-12">Famous Landmarks</h2> */}

        {/* Blog Post Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">

          <div className="bg-transparent rounded-lg overflow-hidden">
            <div className="h-auto">
              <img src={blogTwo} alt="blog-one" className="w-full h-full object-cover"/>
            </div>
            
          </div>

          <div className="bg-white rounded-lg shadow-md overflow-hidden">
            <div className="h-48 bg-gray-200">
              <img src={blogFour} alt="blog-one" className="w-full h-full object-cover"/>
            </div>
            <div className="p-6">
              <h3 className="text-xl font-semibold">Explore and Visit places in Varanasi Like a Local: Your Guide to Authentic, Eco-Friendly Travel with Custom Itineraries</h3>
              <p className="mt-4 text-gray-600">
              Travelers flock to Varanasi or Banaras for its rich history, spiritual ambiance, and vibrant culture, but truly experiencing the essence of this city can be challenging without local insights. With VoyEase, you can explore Varanasi in a way that’s authentic, eco-friendly, and tailored to your preferences. In this post, we’ll cover the best ways to enjoy Varanasi through local guides, sustainable practices, and personalized itineraries.
              </p>
              <a href="/blog-one" className="text-blue-500 hover:underline mt-4 block">Read More →</a>
            </div>
          </div>

          <div className="bg-transparent rounded-lg overflow-hidden">
            <div className="h-auto">
              <img src={blogOne} alt="blog-one" className="w-full h-full object-cover"/>
            </div>
            
          </div>


          {/* <div className="bg-white rounded-lg shadow-md overflow-hidden">
            <div className="h-56 bg-gray-200">
              <img src={blogTwo} className="w-full h-full object-cover" />
            </div>
            <div className="p-6">
              <h3 className="text-xl font-semibold">Real-Time Booking: Convenience at Your Fingertips</h3>
              <p className="mt-4 text-gray-600">
              In today’s fast-paced world, VoyEase provides the convenience of real-time booking with just a few clicks. You can plan or adjust your travel itinerary on the go, making it ideal for solo travelers, families, or groups seeking flexibility. Skip the fixed schedules and create an adventure that’s entirely your own.
              </p>
              <a href="#" className="text-blue-500 hover:underline mt-4 block">Read More →</a>
            </div>
          </div> */}


          {/* <div className="bg-white rounded-lg shadow-md overflow-hidden">
            <div className="h-56 bg-gray-200">
              <img src={blogThree} className="w-full h-full object-cover" />
            </div>
            <div className="p-6">
              <h3 className="text-xl font-semibold">Eco-Friendly Travel Tips for Varanasi Visitors</h3>
              <p className="mt-4 text-gray-600">
              At VoyEase, we’re committed to promoting sustainable travel. Here are some eco-friendly tips to enhance your experience in Varanasi:
              <ul>
                <li><b>Use reusable water bottles:</b> Avoid single-use plastic by carrying your own bottle.</li>
                <li><b>Opt for eco-friendly transport:</b> VoyEase offers options that help reduce carbon footprints.</li>
                <li><b>Support local artisans:</b> Shopping locally not only reduces the environmental impact but also boosts the local economy.</li>
              </ul>
              Sustainable travel isn’t just about reducing waste; it’s about engaging with a place respectfully and thoughtfully. By making eco-friendly choices, VoyEase travelers contribute to preserving Varanasi’s charm for future generations.
              </p>
              <a href="#" className="text-blue-500 hover:underline mt-4 block">Read More →</a>
            </div>
          </div> */}


          {/* <div className="bg-white rounded-lg shadow-md overflow-hidden">
            <div className="h-56 bg-gray-200">
              <img src={blogFour} className="w-full h-full object-cover" />
            </div>
            <div className="p-6">
              <h3 className="text-xl font-semibold">Supporting Women in Tourism</h3>
              <p className="mt-4 text-gray-600">
              VoyEase is proud to partner with women tour guides, promoting equal opportunities and offering unique perspectives. Engaging with women entrepreneurs breaks stereotypes and empowers communities, enriching your travel experience with diverse cultural insights.
              </p>
              <a href="#" className="text-blue-500 hover:underline mt-4 block">Read More →</a>
            </div>
          </div> */}


          {/* <div className="bg-white rounded-lg shadow-md overflow-hidden">
            <div className="h-56 bg-gray-200">
              <img src={blogFive} alt="Taj Mahal" className="w-full h-full object-cover" />
            </div>
            <div className="p-6">
              <h3 className="text-xl font-semibold">Craft Your Custom Itinerary with Local Insights</h3>
              <p className="mt-4 text-gray-600">
              With VoyEase, you can create a custom itinerary that reflects your interests, budget, and schedule. Our local guides work with you to design a travel plan that’s truly yours, whether you want to visit hidden temples, join spiritual ceremonies, or explore local markets. This level of personalization ensures that your journey is aligned with your unique travel style, making every experience unforgettable.
              </p>
              <a href="#" className="text-blue-500 hover:underline mt-4 block">Read More →</a>
            </div>
          </div> */}


          {/* <div className="bg-white rounded-lg shadow-md overflow-hidden">
            <div className="h-56 bg-gray-200">
              <img src={blogSix} alt="Statue of Liberty" className="w-full h-full object-cover" />
            </div>
            <div className="p-6">
              <h3 className="text-xl font-semibold"> How VoyEase Enhances Safety and Convenience</h3>
              <p className="mt-4 text-gray-600">
              Safety is our priority at VoyEase. We carefully vet each guide, ensuring they meet high standards of quality, knowledge, and trustworthiness. With our focus on secure, personalized tours, you can explore Varanasi confidently, knowing that you’re in good hands.
              </p>
              <p className="mt-4 text-gray-600">Ready to experience Varanasi like never before? Book a local guide with VoyEase today and create unforgettable memories while supporting sustainable and inclusive tourism.</p>
              <p className="mt-4 text-gray-600">Exploring Varanasi with VoyEase means more than just sightseeing—it’s about immersive experiences that support local culture, eco-friendly travel, and meaningful connections. Join us in setting a new standard for travel, where every journey enriches communities and celebrates cultural richness.</p>
              <a href="#" className="text-blue-500 hover:underline mt-4 block">Read More →</a>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Blog;
