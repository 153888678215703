import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="px-4 py-6">
      <h1 className="text-xl font-bold">Privacy Policy</h1>
      <br />
      <p>
        Your privacy matters to Voyease solutions pvt Ltd. (the “Company”, “we”, “Voyease”, “us”
        or “our”).
        This Privacy Policy (“Policy”) describes the policies and procedures on the collection, use,
        processing, storage, retrieval, disclosure, transfer and protection of your information,
        including personal information and sensitive personal data or information
        (“Information”), that Voyease may receive through your online access, interaction or use,
        of the Voyease mobile applications (“Voyease App”) or our website located
        at https://www.voyease.in (the website and Voyease App are collectively referred to as the
        “Voyease Platform”) or through your offline interaction with us including through mails,
        phones, in person, etc., or while availing our Services.
        The terms “you” and “your” refer to a guide(defined below), a buddy (defined below), a
        customer (defined below) or any other user of the Voyease Platform and / or availing the
        Services (defined below).
        The term “Services” refers to any services offered by Voyease in accordance with the terms
        and conditions applicable to you (and available on the Voyease Platform) whether on the
        Voyease Platform or otherwise.
        Capital terms not defined herein have the meaning assigned to them in the terms and
        conditions applicable to you and available on Voyease Platform.
        Please read this Policy before using the Voyease Platform or submitting any Information to
        Voyease. This Policy is a part of and incorporated within, and is to be read along with, the
        terms and conditions applicable to the users of the Voyease App available on the Voyease
        Platform.
        <br />
        The terms “you” and “your” refer to a guide(defined below), a buddy (defined below), a
        customer (defined below) or any other user of the Voyease Platform and / or availing the
        Services (defined below). <br />
        The term “Services” refers to any services offered by Voyease in accordance with the terms
        and conditions applicable to you (and available on the Voyease Platform) whether on the
        Voyease Platform or otherwise. <br /> <br />
        <p class="text-red-500">Capital terms not defined herein have the meaning assigned to them in the terms and
          conditions applicable to you and available on Voyease Platform. </p><br />

        Please read this Policy before using the Voyease Platform or submitting any Information to
        Voyease. This Policy is a part of and incorporated within, and is to be read along with, the
        terms and conditions applicable to the users of the Voyease App available on the Voyease
        Platform.
      </p> <br />
      <strong>
        <u>1. USER ACCEPTANCE </u>
      </strong>
      <br />
      <p>
        By accessing or using the Voyease Platform or the Services, you agree and consent
        to this Policy, along with any amendments made by the Company at its sole
        discretion and posted on the Voyease Platform from time to time. <br /><br />
        Any collection, processing, retrieval, transfer, use, storage, disclosure and
        protection of your Information will be in accordance with this Policy and applicable
        laws including but not limited to Information Technology Act, 2000, and the rules
        framed thereunder (“Applicable Laws”). If you do not agree with the Policy, please
        do not use or access the Voyease Platform. <br /> <br />
        You hereby represent to Voyease that: <br /><br />

        a. The Information you provide to Voyease from time to time, is and will be
        authentic, correct, current and updated and you have all the rights,
        permissions and consents as may be required to provide such Information
        to Voyease. <br />
        b. Your providing of the Information as well as Voyease's consequent storage,
        collection, usage, transfer, access, or processing of such Information will not
        be in violation of any agreement, Applicable Laws, charter documents,
        judgments, orders and decrees. <br />
        c. If you disclose to us any Information relating to other people, you represent
        that you have the authority to do so and to permit us to use such
        Information in accordance with this Policy.
      </p>




      <li>
        <strong>2. DEFINITIONS</strong> <br /> <br />
        Unless otherwise provided in this Policy, the terms capitalized in the Policy shall
        have the meaning as provided hereunder: <br />
        a. “Guide” means independent third-party tour guide (with guide certificate)
        who wish to offer services on the Voyease Platform to a user/Customer. <br />
        b. “Co-branded Services” shall have the meaning assigned to the term in
        paragraph 5(c) hereto. <br />
        c. “Customer” shall mean a person who wishes to receive services of the
        Guides or buddies on the Voyease Platform.  <br />
        d. “Device” shall mean computer, mobile or other device used to access the
        Services. <br />
        e. “Device Identifier” shall mean IP address or other unique identifier of the
        Device. <br />
        f.
        “Promotion” shall mean any contest and other promotions offered by us.
        g. “Personal Information” shall mean such categories of information that could
        reasonably be used to identify you personally, including your name, e-mail
        address, and mobile number.  <br />
        h. “TPSP” shall mean a third-party service provider.
        i.
        “Usage Information” shall have the meaning assigned to the term in
        paragraph 3(II) hereto. <br />
        j.
        “Buddy” shall mean independent third party tour providers who offer tour &
        travel services on the Voyease Platform. <br />
      </li>
      <br />
      <li>
        <div class="space-y-6">
          <h2 class="text-xl font-semibold">3. WHAT INFORMATION DO WE COLLECT?</h2>

          <h3 class="text-lg font-semibold">I. INFORMATION YOU PROVIDE TO US</h3>

          <p class="text-base">
            <strong>a. Personal Information:</strong> We may ask you to provide certain Personal Information to us. We may collect this information through various means and in various places for the provision of Services, including account registration forms, contact us forms, or when you otherwise interact with us. When you sign up to use the Services, you create a user profile. We shall ask you to provide only such Personal Information which is for lawful purpose connected with our Services and necessary to be collected by us for such purpose.
          </p>

          <p class="text-base">
            <strong>b. The Information you provide to us includes the following:</strong>
          </p>

          <ul class="list-disc list-inside space-y-2 pl-5 text-base">
            <li>
              <strong>Account Information:</strong> Create or update your Voyease account which may include your email address, name, address, mobile number, gender, date of birth, photograph, login name, password, banking or payment related information (as permitted by Applicable Laws), etc.
            </li>
            <li>
              <strong>Saved Information:</strong> While you use our Services, we may collect and store Information about you to process your requests and automatically complete forms for future transactions, including (but not limited to) your phone number, address, email address, billing information, emergency contact information, etc.
            </li>
            <li>
              <strong>Verification Information:</strong> If you are a Guide or Buddy, we may collect location details, profile picture, call and SMS details, copies of government issued identification documents such as Aadhaar, Permanent Account Number, etc., guide license details(Guide certificate), and other details (KYC), to provide Services on the Voyease Platform from time to time. If you are a Guide or Buddy we may also require you to capture your real time self-clicked images (selfies) and upload such selfies on the Voyease Platform from time to time to verify your identity.
            </li>
            <li>
              <strong>Background check Information:</strong> We collect background check and identity verification information of the Guides and Buddies. This may include collection of Information such as criminal records (where permitted by law), and right to work. This information may also be collected by TPSP on the Company’s behalf.
            </li>
            <li>
              <strong>Other Apps:</strong> In case you sign up as a Guide or Buddy, enable features that require Voyease’s access to other applications on your Device aiming to prevent and detect fraud towards the Customers.
            </li>
            <li>
              <strong>Other Information:</strong> We collect additional Information you provide when you correspond with Voyease for customer support or report problems for troubleshooting. We also collect Information that you may submit electronically such as when you use in-app messaging, post on any message boards, provide ratings, reviews, or comments. In case you refer a friend, we may also collect, store, and use the name and contact information of your friend to promote our Services.
            </li>
            <li>
              <strong>In addition to the foregoing, you will not upload, display, share, host, publish or transmit any information that:</strong>
              <ul class="list-disc list-inside space-y-2 pl-5">
                <li>Is harmful, offensive, harassing, obscene, pornographic, invasive of another’s privacy, hateful, racially or ethnically objectionable, disparaging, relating to or encouraging money laundering or gambling, or an online game that causes user harm, or otherwise unlawful in any manner whatsoever, or promoting enmity between different groups on the grounds of religion or caste with the intent to incite violence;</li>
                <li>Deceives or misleads the addressee about the origin of such messages, or communicates any misinformation or information which is patently false and untrue or misleading in nature;</li>
                <li>Threatens the unity, integrity, defense, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation.</li>
              </ul>
            </li>
          </ul>
        </div>


        <div class="mb-4">
          <h3 class="font-semibold">II. INFORMATION WE COLLECT AS YOU ACCESS AND USE VOYEASE APP</h3>
          <p class="mb-2">▪ Transaction Information: We collect transaction Information such as trip details, pick-up and drop-off addresses, distance travelled, payment transaction information (subject to Applicable Laws), etc.</p>
          <p class="mb-2">
            ▪ Location data:
            <ul class="ml-4 list-disc">
              <li><strong>Guides and Buddies:</strong> Voyease collects location data when the Voyease App is running in the foreground (app is open and on-screen) or background (app is not in use) in your mobile device. For Guides or Buddies, Voyease collects location data only when the Guides or Buddies have enabled the icon “On Duty” on the Voyease App.</li>
              <li><strong>Customers:</strong> We collect precise or approximate location data from your Device if you enable us to do so. Voyease collects this data from the time a Service is requested until it is finished, and any time the app is running in the foreground of your Device. We use this data to enhance your use of Voyease App, including to improve pick-up locations, enable safety features, and prevent and detect fraud. Even if you have not enabled us to collect location data from your Device, Voyease collects the Guides’ or Buddies location data collected during a trip, and links such location data with your account. This enables us to offer services to you, such as receipt generation and customer support, and enable safety of the Customers.</li>
            </ul>
          </p>
          <p class="mb-2">▪ Usage Information: We, our TPSP may use a variety of technologies that automatically (or passively) collect certain Information whenever you visit or interact with the Voyease Platform for obtaining the Services (“Usage Information”). This Usage Information may include the browser that you are using, the URL that referred you to our Services, all of the areas within our Services that you visit, and the time of day, searches and search results, or usage behavior on the Voyease App, etc.</p>
          <p class="mb-2">▪ Health related Information: If you are a Guide or Buddy, we may collect certain health related Information and declarations such as body temperature, symptoms, vaccination status and other health conditions.</p>
          <p class="mb-2">▪ Device Information: We collect Information by ourselves or through integration with third-party applications which consists of technical information and aggregated usage information, and may contain, among other things, Device Identifier of your Device, your preferred language and country site, manufacturer, software, and model of your Device, Device type, operating systems and versions, your geo location, mobile network data, screens you have visited, your touch gestures performed in your Voyease App, your scrolling activity, and any other actions you have performed during your use of Voyease App, etc., to enhance user interface and experience on Voyease Platform, facilitate the provision of software updates, product support and other services to you, etc. Any sensitive information about other programs that you are running on your Device, passwords, and activity across other applications are not collected and all the sensitive information are masked.</p>
          <p class="mb-2">▪ SMS/Text Messages: We may collect data from SMS/ text messages upon receiving Device access permissions from you for the purposes of (i) issuing and receiving one-time passwords and other device verification, and (ii) automatically filling verification details during financial transactions, either through us or a TPSP, in accordance with Applicable Laws. We do not share or transfer SMS/ text message data to any third party other than as provided under this Policy.</p>
          <p class="mb-2">▪ Call details: If you are a Buddy or a Guide, we will, additionally, record your calls with us made from the Device used to provide Services and related call details.</p>
          <p class="mb-2">▪ Other Information: We collect Information about how you interact with the Voyease App and any of our web sites to which the Voyease App links, such as how many times you use a specific part of the Voyease App over a given time period, the amount of time you spend using the Voyease App , how often you use the Voyease App, actions you take in the Voyease App and how you engage with the Voyease App, etc.</p>
          <p class="mb-2">▪ Cookies: Usage Information may be collected using a cookie. If you do not want information to be collected through the use of cookies, your browser / app settings allows you to deny or accept the use of cookies. Cookies can be disabled or controlled by setting a preference within your web browser or on your Device. If you choose to disable cookies or flash cookies on your Device, some features of the Services may not function properly or we may not be able to customize the delivery of information to you. The Company cannot control the use of cookies (or the resulting information) by third parties, and use of third-party cookies is not covered by our Policy.</p>
        </div>
        <div class="mb-4">
          <h3 class="font-semibold">III. INFORMATION THIRD PARTIES PROVIDE ABOUT YOU</h3>
          <p class="mb-2">We may, from time to time, collect Information about you through Voyease Platform or while availing the Services and collect Information from our affiliates or third parties / TPSPs such as technical sub-contractors, business partners, analytics providers, search information providers, payment service providers, etc., and also from publicly available sources such as commercially available marketing lists, social networks and other related media.</p>
        </div>



        <div class="px-6 py-4">
          <h2 class="text-lg font-semibold mb-4">4. USE OF INFORMATION COLLECTED</h2>
          <p class="mb-4">
            Our primary objective in collecting your information is to enhance your experience when using the Services. We may use the collected information in accordance with this Policy for the following purposes:
          </p>

          <ul class="list-decimal list-inside space-y-2">
            <li>To enable your access to the Voyease Platform.</li>
            <li>To verify your identity and/or your eligibility, under applicable law, to use Services on the Voyease Platform.</li>
            <li>To monitor usage patterns and identify which features of the Services are most used, allowing you to view your trip history, rate trips or bookings, and assess areas for improvement.</li>
            <li>To send you a welcome email/SMS to verify your username and password.</li>
            <li>To provide the appropriate app version based on your device type, for troubleshooting, and occasionally for marketing purposes.</li>
            <li>To diagnose issues with our server and administer the Voyease Platform.</li>
            <li>To send essential service-related announcements on rare occasions (e.g., if Services are temporarily unavailable). If you do not wish to receive these notifications, you may deactivate your account.</li>
            <li>To prevent, discover, and investigate violations of this Policy or any applicable terms of service.</li>
            <li>To identify and detect security breaches or attacks, errors, fraud, money laundering, abuse, and other criminal activities, and to take appropriate remedial action.</li>
            <li>To share some Personal Information (e.g., name, pickup address, contact number) with the Guide or Buddy accepting your service request, allowing them to contact and locate you.</li>
            <li>If you are a Customer, to use geo-location information for various purposes, such as viewing Guides or Buddies nearby, fetching your location, and sharing this with others for safety.</li>
            <li>If you are a Guide or Buddy, to use geo-location information (only when “On-Duty” is activated on the Voyease App) to receive orders nearby, enable Customers to track your distance for pickup, and share exact locations for Customer safety.</li>
            <li>If you are a Guide or Buddy, to share your name, phone number, and/or profile picture (if applicable) with Customers to facilitate Services.</li>
            <li>To provide information, services, or process transactions requested, such as newsletters, special offers, or promotional materials.</li>
            <li>To allow participation in Services features, including sweepstakes, contests, and other promotions.</li>
            <li>To contact you about your Services usage or changes in Services policies.</li>
            <li>To conduct internal business operations, including data analytics and performance analysis.</li>
            <li>To personalize the Voyease App experience to enhance user experience.</li>
            <li>To provide relevant offers or rewards based on your consumption patterns.</li>
            <li>To enforce our terms and conditions, resolve disputes, and improve platform usability.</li>
            <li>To comply with Applicable Laws or requests from regulators, law enforcement, or judicial authorities.</li>
            <li>To fulfill obligations and enforce rights arising from contracts with users or third parties.</li>
            <li>To disclose information to affiliates, employees, agents, and representatives for service facilitation.</li>
            <li>To deliver administrative notices, alerts, or relevant communications through SMS, social media, or other media channels.</li>
            <li>If signed up as an employee or stakeholder of a partner with discount offers, to share information with that partner for limited internal purposes.</li>
            <li>To fulfill any other purpose for which you provide Information or with your consent.</li>
          </ul>

          <p class="mt-4">
            Please note, we do not use the information collected from you for targeted advertising.
          </p>
        </div>


        <div class="px-6 py-4">
          <h2 class="text-lg font-semibold mb-4">5. HOW AND WHEN DO WE DISCLOSE INFORMATION TO THIRD PARTIES</h2>
          <p class="mb-4">
            We do not sell, share, rent, or trade the information we have collected about you, other than as disclosed within this Policy or at the time you provide your Information. Following are the situations when Information may be shared:
          </p>

          <div class="mb-4">
            <h3 class="font-semibold mb-2">a. WHEN YOU AGREE TO SHARE INFORMATION WITH THIRD PARTIES:</h3>
            <p>
              You may opt to receive information and/or marketing offers directly from third parties when you access third-party links on the Voyease App. If you agree to have your Personal Information shared, it will be disclosed to these third parties and subject to their privacy policies. We are not responsible for these policies, so please review them before consenting. If you later decide to stop communication from a third party, contact them directly.
            </p>
          </div>

          <div class="mb-4">
            <h3 class="font-semibold mb-2">b. THIRD PARTIES PROVIDING SERVICES ON OUR BEHALF</h3>
            <p>
              We may share your Information with TPSPs, business partners, and agents. Refer to the third-party’s privacy policy before using their services on the Voyease App. TPSPs help facilitate our Services by hosting, designing, or operating features, processing payments, performing background checks, providing customer support, offering geo-location info to Guides/Buddies, and more. These third parties will only have access to Information necessary to perform their tasks. We require TPSPs to ensure data protection and limit their use of Personal Information.
            </p>
          </div>

          <div class="mb-4">
            <h3 class="font-semibold mb-2">c. CO-BRANDED SERVICES</h3>
            <p>
              Certain aspects of the Services may be provided with third parties (“Co-Branded Services”), such as credit houses or sponsors, requiring you to disclose Information. Co-Branded Services will identify the third party. If you register for products/services through these services, you consent to share Information with both us and the third party, subject to their privacy policies.
            </p>
          </div>

          <div class="mb-4">
            <h3 class="font-semibold mb-2">d. CONTESTS AND PROMOTIONS</h3>
            <p>
              We may offer Promotions that require registration. By participating, you agree to the Promotion's rules, which may include allowing the sponsor to use your name, voice, and/or likeness in marketing. Your Personal Information may be disclosed for winner selection, prize fulfillment, or as legally required.
            </p>
          </div>

          <div class="mb-4">
            <h3 class="font-semibold mb-2">e. ADMINISTRATIVE AND LEGAL REASONS</h3>
            <p>
              We cooperate with law enforcement and government officials to comply with laws. We may access, use, preserve, transfer, and disclose your information, including Personal Information, IP address, Device Information, or geo-location data, if necessary to comply with legal requests, protect our terms, or address security issues.
            </p>
          </div>

          <div class="mb-4">
            <h3 class="font-semibold mb-2">f. AFFILIATES AND BUSINESS TRANSFER</h3>
            <p>
              We may share your Information with our parent, subsidiaries, and affiliates for internal purposes. We reserve the right to disclose all such information to a subsequent owner, co-owner, or operator of the Services, or during corporate changes such as mergers or sales.
            </p>
          </div>

          <div class="mb-4">
            <h3 class="font-semibold mb-2">g. MARKET STUDY AND OTHER BENEFITS</h3>
            <p>
              We may share your information, including Personal Information, with third parties for purposes such as market research, data analysis, product or service customization, and improvements to make benefits/products/services available to you.
            </p>
          </div>
        </div>



        <div class="px-8 py-4 space-y-6 text-gray-900 bg-white">
          <h2 class="text-2xl font-semibold">6. THIRD PARTY CONTENT AND LINKS TO THIRD PARTY SERVICES</h2>

          <p class="text-base">
            The Services may contain content that is supplied by a third party, and those third parties may collect website usage information and your Device Identifier when web pages from any online or mobile Services are served to your browser. In addition, when you are using the Services, you may be directed to other sites or applications that are operated and controlled by third parties that we do not control, in which case our Policy will no longer apply. We are not responsible for the privacy practices employed by any of these third parties.
          </p>

          <p class="text-base">
            For example, if you click on a banner advertisement, the click may take you away from Voyease App onto a different web site. These other websites may send their own cookies to you, independently collect data or solicit Information and may or may not have their own published privacy policies.
          </p>

          <p class="text-base">
            Information (including Personal Information) may be collected by third parties if there is content from the Voyease App that you specifically and knowingly upload to, share with or transmit to an email recipient, online community, website, or to the public, e.g. uploaded photos, posted reviews or comments, or information about you or your ride or booking that you choose to share with others through features which may be provided on our Services. This uploaded, shared, or transmitted content will also be subject to the privacy policy of the email, online community website, social media, or other platform to which you upload, share or transmit the content. We are not responsible for the privacy practices employed by any of these third parties.
          </p>

          <p class="text-base">
            Our online and mobile Services may include social media features, such as the Facebook Like button, and widgets such as a “Share This” button, or interactive mini-programs that run on Voyease App. These features may collect Information including your IP address, photograph, which page you are visiting on our online or mobile Services, and may set a cookie to enable the feature to function properly. Social media features and widgets are either hosted by a third party or hosted directly on our online Services and/or Voyease Platform. Your interactions with these features and widgets are governed by the privacy policy of the company providing them and we will not be responsible or liable for any acts or omissions of such third parties.
          </p>

          <p class="text-base">
            In particular, remember that certain third parties may be located in or have facilities that are located in a different jurisdiction, hence, if you elect to proceed with a transaction that involves the services of a third-party service provider, then your information may become subject to the laws of the jurisdiction(s) in which such service provider is, or its facilities are located. We encourage you to note when you leave web pages or links controlled by Voyease App/ Services and to read the privacy statements of all third party web sites or applications before submitting any Information to such third parties. We will not be liable for any acts or omissions of the third-party service providers.
          </p>
        </div>


        <div class="px-8 py-4 space-y-6 text-gray-900 bg-white">
          <h2 class="text-2xl font-semibold">6. THIRD PARTY CONTENT AND LINKS TO THIRD PARTY SERVICES</h2>

          <p class="text-base">
            The Services may contain content that is supplied by a third party, and those third parties may collect website usage information and your Device Identifier when web pages from any online or mobile Services are served to your browser. In addition, when you are using the Services, you may be directed to other sites or applications that are operated and controlled by third parties that we do not control, in which case our Policy will no longer apply. We are not responsible for the privacy practices employed by any of these third parties.
          </p>

          <p class="text-base">
            For example, if you click on a banner advertisement, the click may take you away from Voyease App onto a different web site. These other websites may send their own cookies to you, independently collect data or solicit Information and may or may not have their own published privacy policies.
          </p>

          <p class="text-base">
            Information (including Personal Information) may be collected by third parties if there is content from the Voyease App that you specifically and knowingly upload to, share with or transmit to an email recipient, online community, website, or to the public, e.g. uploaded photos, posted reviews or comments, or information about you or your ride or booking that you choose to share with others through features which may be provided on our Services. This uploaded, shared, or transmitted content will also be subject to the privacy policy of the email, online community website, social media, or other platform to which you upload, share or transmit the content. We are not responsible for the privacy practices employed by any of these third parties.
          </p>

          <p class="text-base">
            Our online and mobile Services may include social media features, such as the Facebook Like button, and widgets such as a “Share This” button, or interactive mini-programs that run on Voyease App. These features may collect Information including your IP address, photograph, which page you are visiting on our online or mobile Services, and may set a cookie to enable the feature to function properly. Social media features and widgets are either hosted by a third party or hosted directly on our online Services and/or Voyease Platform. Your interactions with these features and widgets are governed by the privacy policy of the company providing them and we will not be responsible or liable for any acts or omissions of such third parties.
          </p>

          <p class="text-base">
            In particular, remember that certain third parties may be located in or have facilities that are located in a different jurisdiction, hence, if you elect to proceed with a transaction that involves the services of a third-party service provider, then your information may become subject to the laws of the jurisdiction(s) in which such service provider is, or its facilities are located. We encourage you to note when you leave web pages or links controlled by Voyease App/ Services and to read the privacy statements of all third party web sites or applications before submitting any Information to such third parties. We will not be liable for any acts or omissions of the third-party service providers.
          </p>
        </div>


        <div class="px-8 py-4 space-y-6 text-gray-900 bg-white">
          <h2 class="text-2xl font-semibold">7. INFORMATION COLLECTED BY GUIDES AND BUDDIES</h2>
          <p class="text-base">
            This Policy does not cover the usage of any information about you which is obtained by the Guides or Buddies while providing you a Guiding, Travel & Tour Services, or otherwise.
          </p>

          <h2 class="text-2xl font-semibold">8. CHANGE OF INFORMATION AND CANCELLATION OF ACCOUNT</h2>

          <div class="space-y-4">
            <p class="text-base">
              <strong>a.</strong> You are responsible for maintaining the accuracy of the Information you submit to us, such as your contact information provided as part of account registration.
            </p>

            <p class="text-base">
              <strong>b.</strong> If your Personal Information or Information you provide to us changes, or if you no longer desire our Services, you may correct, delete inaccuracies, or amend information by making the change on our member information page or by contacting us through the email address mentioned on Voyease Platform, or contacting the Grievance Officer. We will make good faith efforts to make requested changes in our then active databases as soon as reasonably practicable.
            </p>

            <p class="text-base">
              <strong>c.</strong> You may also cancel or modify the communications that you have elected to receive from Voyease by following the instructions contained within an email or by logging into your user account and changing your communication preferences.
            </p>

            <p class="text-base">
              <strong>d.</strong> If upon modifying or changing the Information earlier provided to Us, we find it difficult to provide access to our Services to you due to insufficiency/inaccuracy of the Information, we may, in our sole discretion, terminate your access to the Services by providing you a written notice to this effect on your registered email address.
            </p>

            <p class="text-base">
              <strong>e.</strong> If you wish to cancel your account or request that we no longer use your Information to provide you services, contact us through the email address mentioned on the trip bill received, the Voyease Platform, or the Grievance Officer mentioned in this Policy. Please note, we may not be able to provide some or all of the Services in case you disable access to any of your Information as described under this Policy.
            </p>

            <p class="text-base">
              <strong>f.</strong> We will retain your Information including Personal Information and Usage Information (including geo-location) for as long as your account with the Services is active and as needed to provide you services. Even after your account is terminated, we will retain some of your Information including Personal Information and Usage Information (including geo-location, trip history, and transaction history) for a period of 180 days, to resolve disputes, conclude any activities related to cancellation of an account, investigate, or prevent fraud and other inappropriate activity related to your account, to enforce our agreements, or for other business reasons, etc. After completion of such period, your data may either be deleted from our database or be anonymized and aggregated, and then may be held by us as long as necessary for us to provide our Services effectively, but our use of the anonymized data will be solely for analytic purposes.
            </p>
          </div>
        </div>


        <div class="px-8 py-4 space-y-6 text-gray-900 bg-white">
          <h2 class="text-2xl font-semibold">9. SECURITY</h2>
          <p class="text-base">
            The Information we collect is securely stored within our databases, and we use standard, industry-wide, commercially reasonable security practices such as encryption, firewalls, and SSL (Secure Socket Layers) for protecting your Information. However, as effective as encryption technology is, no security system is impenetrable. We cannot guarantee the security of our databases, nor can we guarantee that Information you supply won't be intercepted while being transmitted to us over the Internet or wireless communication, and any Information you transmit to us, you do at your own risk. We recommend that you not disclose your password to anyone.
          </p>

          <h2 class="text-2xl font-semibold">10. INFORMATION OF CHILDREN</h2>
          <p class="text-base">
            We do not knowingly solicit or collect Information from children under the age of 18 years. Use of the Voyease App is only available for persons who can enter into a legally binding contract under Applicable Laws.
          </p>

          <h2 class="text-2xl font-semibold">11. GRIEVANCE OFFICER</h2>
          <p class="text-base">
            If you would like to ask about, make a request relating to, or complain about how We process your information, please contact or email our grievance officer, at one of the addresses below. Our grievance officer will attempt to expeditiously redress your grievances.
          </p>

          <div class="space-y-4">
            <p class="text-base"><strong>For any request, complaint, feedback, or grievances, please contact:</strong></p>
            <ul class="list-disc pl-6 space-y-2">
              <li><strong>Name:</strong> Pankaj Sharma</li>
              <li><strong>Email details:</strong> <a href="mailto:pankaj@voyease.in" class="text-blue-500 hover:underline">pankaj@voyease.in</a></li>
            </ul>
          </div>
        </div>


        <div class="px-8 py-4 space-y-6 text-gray-900 bg-white">
          <h2 class="text-2xl font-semibold">12. CHANGES TO THE PRIVACY POLICY</h2>
          <p class="text-base">
            We reserve the right to update / modify, from time to time, this Policy to reflect changes to our Information practices. Any changes will be effective immediately upon the posting of the revised Policy on the Voyease Platform. If we make any material changes, we will notify you by email (sent to the e-mail address specified in your account) or by means of a notice on the Voyease App prior to the change becoming effective. We encourage you to periodically review this page for the latest information on our privacy practices.
          </p>
          <p class="text-base">
            Your use of the Voyease App or availing the Services after an updated Policy becomes effective will indicate your acceptance of the updated Policy.
          </p>
        </div>






























        
        
      
        
        
      </li>

















    </div>
  );
};

export default PrivacyPolicy;
