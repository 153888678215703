import React from 'react';
import blogOne from '../assets/img/blog/pexels-bhaveshjain-3988075.jpg';
import blogTwo from '../assets/img/blog/pexels-rachel-claire-8112558.jpg';
import blogThree from '../assets/img/blog/pexels-ravijha95-12112985.jpg';
import blogFive from '../assets/img/blog/pexels-rachel-claire-8112531.jpg';
// import blogFour from '../assets/img/blog/pexels-rachel-claire-8112555.jpg';
// import blogSix from '../assets/img/blog/pexels-subhajit-paul-213168853-12375961.jpg';

const BlogOne = () => {
  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Hero Section */}
      <div className="relative h-40">
        <div className="absolute inset-0 opacity-50"></div>
        <div className="relative z-10 flex flex-col items-center justify-center h-full text-center text-black px-5 w-3/4 m-auto">
          <h1 className="sm:text-2xl md:text-3xl lg:text-4xl font-bold">Explore and Visit places in Varanasi Like a Local: Your Guide to Authentic, Eco-Friendly Travel with Custom Itineraries</h1>
        </div>
      </div>

      {/* Gallery Section */}
      <div className="py-16 px-4 md:px-8 max-w-screen-lg mx-auto">
        {/* <h2 className="text-3xl font-bold text-center mb-12">Gallery</h2> */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {/* Image 1 */}
          <img src={blogOne} alt="Burj Khalifa 1" className="w-full h-64 object-cover rounded-lg shadow-md" />
          {/* Image 2 */}
          <img src={blogThree} alt="Burj Khalifa 2" className="w-full h-64 object-cover rounded-lg shadow-md" />
          {/* Image 3 */}
          <img src={blogFive} alt="Burj Khalifa 3" className="w-full h-64 object-cover rounded-lg shadow-md" />
        </div>
      </div>

      {/* Content Section */}
      <div className="py-16 px-4 md:px-8 max-w-screen-lg mx-auto">
        <div className="flex flex-col lg:flex-row gap-8">
          {/* Main Content */}
          <div className="lg:w-3/3">
            <h2 className="text-2xl font-bold mb-6">1. Why Choose Local Guides for an Authentic Experience</h2>
            <p className="text-gray-700 mb-4">
            Navigating Varanasi’s bustling lanes and hidden temples can be overwhelming, and without a local guide, travelers may miss out on its hidden gems. When you book with VoyEase, you’re connecting with local experts who offer unique insights, such as the best time for a Ganga aarti ceremony or hidden spots for authentic street food. Choosing a local guide supports local livelihoods while offering a richer, more meaningful travel experience.
            </p>
            <h2 className="text-2xl font-bold mb-6">2. Real-Time Booking: Convenience at Your Fingertips</h2>
            <p className="text-gray-700 mb-4">
            In today’s fast-paced world, VoyEase provides the convenience of real-time booking of tour guides with just a few clicks. You can plan or adjust your travel itinerary on the go, making it ideal for solo travelers, families, or groups seeking flexibility. Skip the fixed schedules and create an adventure that’s entirely your own.
            </p>
            <h2 className="text-2xl font-bold mb-6">3. Eco-Friendly Travel Tips for Varanasi Visitors</h2>
            <p className="text-gray-700 mb-4">
            At VoyEase, we’re committed to promoting sustainable travel. Here are some eco-friendly tips to enhance your experience in Varanasi:</p>
            <ol>
              <li><b>Use reusable water bottles:</b> Avoid single-use plastic by carrying your own bottle.</li>
              <li><b>Opt for eco-friendly transport:</b> VoyEase offers options that help reduce carbon footprints.</li>
              <li><b>Support local artisans:</b> Shopping locally not only reduces the environmental impact but also boosts the local economy.</li>
            </ol><br />
            <p>
            Sustainable travel isn’t just about reducing waste; it’s about engaging with a place respectfully and thoughtfully. By making eco-friendly choices, VoyEase travelers contribute to preserving Varanasi’s charm for future generations.
            </p>
            <h2 className="text-2xl font-bold mb-6">4. Supporting Women in Tourism</h2>
            <p className="text-gray-700 mb-4">
            VoyEase is proud to partner with women tour guides, promoting equal opportunities and offering unique perspectives. Engaging with women entrepreneurs breaks stereotypes and empowers communities, enriching your travel experience with diverse cultural insights.
            </p>
            <h2 className="text-2xl font-bold mb-6">5. Craft Your Custom Itinerary with Local Insights</h2>
            <p className="text-gray-700 mb-4">
            With VoyEase, you can create a custom itinerary that reflects your interests, budget, and schedule. Our local guides work with you to design a travel plan that’s truly yours, whether you want to visit hidden temples, join spiritual ceremonies, or explore local markets. This level of personalization ensures that your journey is aligned with your unique travel style, making every experience unforgettable.
            </p>
            <h2 className="text-2xl font-bold mb-6">6. How VoyEase Enhances Safety and Convenience</h2>
            <p className="text-gray-700 mb-4">Safety is our priority at VoyEase. We carefully vet each guide, ensuring they meet high standards of quality, knowledge, and trustworthiness. With our focus on secure, personalized tours, you can explore Varanasi confidently, knowing that you’re in good hands.</p>
            

            <img src={blogTwo} alt="Burj Khalifa 1" className="w-full h-96 mb-6 object-cover rounded-lg shadow-md" />

            <h2 className="text-xl font-bold mb-2">Places to visit in Varanasi</h2>
            <ol class="list-decimal ml-5 mb-4">
              <li>Kashi Vishwanath Temple</li>
              <li>Shops for Banarasi silk sarees</li>
              <li>Annapurna Temple</li>
              <li>BHU campus</li>
              <li>Visalakshi Devi or Amman Temple</li>
              <li>Shri Kal Bhairav Temple</li>
              <li>Mrityunjay Mahadev Temple, Birla Temple</li>
              <li>Sankat Mochan Hanuman Temple</li>
              <li>Durga Temple (Durga Kund)</li>
              <li>Tulsi Manas Mandir</li>
              <li>Ganga Aarti at Dashaswamedh Ghat</li>
              <li>Ganga Aarti (Subah-e-Banaras) at Assi Ghat</li>
              <li>Sarnath and Ramnagar Fort</li>
              <li>Other services like Rudrabhishek in Kashi, Pind Daan services</li>
            </ol>

            <p className="text-gray-700 mb-4">
            Ready to experience Varanasi like never before? Book a local tour guide with VoyEase today and create unforgettable memories while supporting sustainable and inclusive tourism. 

            Exploring Varanasi with VoyEase means more than just sightseeing—it’s about immersive experiences that support local culture, budget-friendly travel, and meaningful connections. Join us in setting a new standard for travel, where every journey enriches communities and celebrates cultural richness.
            </p>
          </div>

          
        </div>
      </div>
    </div>
  );
};

export default BlogOne;
