import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../assets/img/logo.png';
import launch_soon from '../assets/img/launch_soon.png';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('home');
  const [showModal, setShowModal] = useState(false); // State to manage modal visibility
  const navigate = useNavigate();
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleScroll = (section) => {
    if (location.pathname !== '/') {
      // Navigate to home route and then scroll to the section
      navigate('/');
      setTimeout(() => {
        document.getElementById(section)?.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    } else {

      setActiveSection(section);
      document.getElementById(section)?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleBlogNavigation = () => {
    setActiveSection('blog');
    navigate('/blog');
  };

  return (
    <div className="bg-white sticky top-0 z-50 flex justify-between items-center px-8 h-20 shadow-md">
      
      <div>
        <img src={logo} alt="Logo" className="h-12 w-auto" />
      </div>

      {/* Hamburger Menu Icon for Mobile */}
      <div className="md:hidden">
        <button onClick={toggleMenu} className="text-3xl">
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </button>
      </div>

      {/* Navigation Links for Desktop */}
      <nav className="hidden md:flex space-x-8">
        <ul className="flex space-x-8">
          <li
            className={`cursor-pointer relative ${activeSection === 'home' ? 'text-orange-600 ' : ''} font-semibold`}
            onClick={() => handleScroll('home')}
          >
            Home
            {activeSection === 'home' && (
              <div className="absolute -bottom-2 left-0 right-0 h-1 bg-orange-600 rounded-tl-full rounded-tr-full"></div>
            )}
          </li>
          <li
            className={`cursor-pointer relative ${activeSection === 'about' ? 'text-orange-600' : ''} font-semibold`}
            onClick={() => handleScroll('about')}
          >
            About Us
            {activeSection === 'about' && (
              <div className="absolute -bottom-2 left-0 right-0 h-1 bg-orange-600 rounded-tl-full rounded-tr-full"></div>
            )}
          </li>
          <li
            className={`cursor-pointer relative ${activeSection === 'contact' ? 'text-orange-600' : ''} font-semibold`}
            onClick={() => handleScroll('contact')}
          >
            Contact Us
            {activeSection === 'contact' && (
              <div className="absolute -bottom-2 left-0 right-0 h-1 bg-orange-600 rounded-tl-full rounded-tr-full"></div>
            )}
          </li>
          <li
            className={`cursor-pointer relative ${activeSection === 'blog' ? 'text-orange-600' : ''} font-semibold`}
            onClick={handleBlogNavigation}
          >
            Our Blog
            {activeSection === 'blog' && (
              <div className="absolute -bottom-2 left-0 right-0 h-1 bg-orange-600 rounded-tl-full rounded-tr-full"></div>
            )}
          </li>
          <li>
            <button
              className="bg-orange-600 text-white px-4 py-2 rounded hover:bg-orange-700 transition duration-300"
              onClick={toggleModal}
            >
              Download App
            </button>
          </li>
        </ul>
      </nav>

      {/* Mobile Menu (only visible when toggled open) */}
      {isOpen && (
        <nav className="absolute top-20 left-0 w-full bg-white shadow-lg md:hidden z-50">
          <ul className="flex flex-col items-center space-y-4 py-4">
            <li className="cursor-pointer font-semibold" onClick={() => handleScroll('home')}>
              Home
            </li>
            <li className="cursor-pointer font-semibold" onClick={() => handleScroll('about')}>
              About Us
            </li>
            <li className="cursor-pointer font-semibold" onClick={() => handleScroll('contact')}>
              Contact Us
            </li>
            <li className="cursor-pointer font-semibold" onClick={handleBlogNavigation}>
              Our Blog
            </li>
            <li>
              <button
                className="bg-orange-600 text-white px-4 py-2 rounded hover:bg-orange-700 transition duration-300"
                onClick={toggleModal}
              >
                Download App
              </button>
            </li>
          </ul>
        </nav>
      )}

      {/* Modal */}
      {showModal && (
        <div
          className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              toggleModal();
            }
          }}
        >
          <div className="relative bg-cover bg-center rounded-xl overflow-hidden w-11/12 md:w-1/3 flex items-center justify-center">
            <div className="p-4 text-right">
              <button onClick={toggleModal} className="text-gray-500 hover:text-gray-800">
                &times;
              </button>
            </div>
            <div className="p-4 text-center">
              
              <div className="image-wrapper">
                <img
                  src={launch_soon}
                  alt="App Image"
                  className="w-full rounded-lg"
                />
              </div>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default Navbar;
